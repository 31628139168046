import React from "react"
import { Star } from "react-feather"

const dummyImage = "https://media.kubric.io/api/assetlib/5340ebfc-ba11-4a08-9f28-b4cc3623ee7a.jpg";

const extraData = {
  availabilityColor1: '#e71d51',
  availabilityColor2: '#da0764'
}

const GridB = ({ data, extras }) => {
  let images = data.nodes.map(({url}) => url ?? dummyImage);
  images  = images.length ? images : Array(4).fill(dummyImage);

  const {availabilityColor1, availabilityColor2} = {...extraData, ...extras.nodes[0]?.meta}

  return (
    <div className="grid sm:grid-cols-1 lg:grid-cols-2 my-8">
        <div className="grid col-span-1 grid-rows-2 grid-cols-2 gap-2">
        {images.map((src) => <img
          // className="w-full"
          src={src}
          alt="placeholder"
          placeholder="blurred"
        />)}
        </div>
        <div className="mx-12 border-2 shadow-2xl px-8 py-4 rounded-lg grid place-items-center lg:mt-0 mt-12">
        <div className="flex w-full justify-between">
        <p className="font-light text-gray-500"><span className="text-black font-bold text-2xl">₹3,307</span> / night</p>
        <p className="flex gap-1 items-center"><Star size={20} color="rgba(255, 57, 91, 1.00)" /> 4.68 <span className="underline text-gray-400 text-sm">(22 reviews)</span></p>
        </div>
          <form className="grid grid-cols-2 grid-rows-2 mb-4 mt-8">
          <label className="flex flex-col p-4 border-t-2 border-l-2 rounded-tl-lg">
            <span className="uppercase font-bold text-xs">Check-in</span>
            <input className="text-gray-400" type="date" placeholder="Add Date" />
          </label>
          <label className="flex flex-col	capitalize p-4 border-t-2 border-l-2 border-r-2 rounded-tr-lg">
            <span className="uppercase font-bold text-xs">Check-out</span>
            <input className="text-gray-400"type="date" placeholder="Add Date" />
          </label>
          <label className="flex flex-col	capitalize col-span-2 p-4 border-2 rounded-b-lg">
            <span className="uppercase font-bold text-xs">Guests</span>
            <select className="text-gray-400" >
              <option value="1">1 Guests</option>
              <option value="2">2 Guests</option>
              <option value="3">3 Guests</option>
              <option value="4">4 Guests</option>
            </select>
          </label>
            <button style={{background: `linear-gradient(to right, ${availabilityColor1}, ${availabilityColor2})`}} className='w-full p-4 col-span-2 mt-4 rounded-lg text-white' type="submit">Check Availability</button>
          </form>
        </div>
    </div>
  )
}

export default GridB
